<template>
  <section id="chat-status-filter">
    <label for="bot-filter">
      {{ lang.metrics.filters.trainerStatus.label[languageSelected] }}
    </label>

    <multiselect
      :key="`multiselect-${key.getTime()}`"
      v-model="value"
      tag-placeholder
      :placeholder="
        lang.metrics.filters.trainerStatus.placeholder[languageSelected]
      "
      label="name"
      track-by="status"
      :options="options"
      :show-labels="false"
      @input="change"
    />

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
    >
      {{ lang.metrics.filters.range.clear[languageSelected] }}
    </vs-button>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TrainerStatusFilter',
  data() {
    return {
      key: new Date(),
      value: {},
      options: []
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    change() {
      this.$emit('change', {
        type: 'trainerStatus',
        value: this.value
      })
    },
    reset() {
      this.value = {}
    },
    clear() {
      this.reset()
      this.change()
    },
    setOptions() {
      this.options = [
        {
          name: this.lang.clustering.questionsTable.statusNames.trained[
            this.languageSelected
          ],
          status: 'trained'
        },
        {
          name: this.lang.clustering.questionsTable.statusNames.not_trained[
            this.languageSelected
          ],
          status: 'not_trained'
        }
      ]
    }
  },
  watch: {
    languageSelected() {
      this.setOptions()
    }
  },
  mounted() {
    this.setOptions()
    // this.change()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#chat-status-filter {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}
</style>
